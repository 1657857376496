<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="panel">

      <H2>OUR MISSION</H2>

      <!-- <div class="sect">&nbsp;Respect for diversity</div>
      <ol>
        <li><a href="#_1.Submission">Respect for diversity</a></li>
        <li><a href="#_2.AIMS AND SCOPE">Aims and Scope</a></li>
        <li><a href="#_3.MANUSCRIPT CATEGORIES AND REQUIREMENTS">Manuscript Categories and Requirements</a></li>
        <li><a href="#_4.DATA PROTECTION">Data Protection</a></li>
        <li><a href="#_5.PREPARING THE SUBMISSION">Preparing the Submission</a></li>
        <li><a href="#_6.EDITORIAL POLICIES AND ETHICAL CONSIDERATIONS">Editorial Policies and Ethical Considerations</a></li>
        <li><a href="#_7.AUTHOR LICENSING AND OPEN ACCESS FEES">Author Licensing and Open Access Fees</a></li>
        <li><a href="#_8.PUBLICATION PROCESS AFTER ACCEPTANCE">Publication Process After Acceptance</a></li>
        <li><a href="#_9.POST PUBLICATION">Post-Publication</a></li>
        <li><a href="#_10.JOURNAL CONTACT DETAILS">Journal Contact Details</a></li>  
      </ol>   -->

      <div class="sect" id="_1.Submission">&nbsp; RESPECT FOR DIVERSITY</div>
      <div class="sect-desc">
        <p>The logo features colorful horizontal stripes, which are intended to depict the scene of marine larvae swimming in the water. Each of these larvae has a distinct form and a different level of smoothness on its surface. This variation represents the rich diversity of species in the ocean. Just as there are countless unique marine organisms, our world is filled with a vast array of individuals, each with their own characteristics and qualities. This diversity is not only a wonder of nature but also a reminder that in the realm of science education, we embrace and celebrate the differences among learners. Every young student comes to us with their own set of interests, abilities, and ways of thinking, and we aim to nurture and develop each one according to their uniqueness. They are all the YOUNG SCIENTISTS.</p>
      </div>

      <div class="sect" id="_2.AIMS AND SCOPE">&nbsp; ALWAYS FIND THE TRUTH</div>
      <div class="sect-desc">
        <p>Despite so much differences, all the elements in the logo are swimming in the same direction. This symbolizes the phototactic behavior of the larvae, their natural inclination to move towards light. Just as the larvae are instinctively attracted to light in search of food, warmth, and a better environment, humans are driven by their curiosity to seek knowledge and understanding of the world. In a broader sense, this represents the innate curiosity that every human being possesses from a very young age. Science, to us, is like that guiding light. It has an irresistible pull that draws people to pursue and explore the unknown world, bravely find the truth.
        </p>
      </div>

      <div class="sect" id="_3.MANUSCRIPT CATEGORIES AND REQUIREMENTS">&nbsp; INTERNATIONAL SCOPE</div>
      <div class="sect-desc">
        <p>In the logo, the colorful horizontal stripes that together form a spherical shape. It represents that science education is global and belongs to all of humanity. Science knows no boundaries; it is a universal pursuit that unites people from all corners of the world. Whether you're from the East or the West, the North or the South, the wonders of science are there to be explored and understood by everyone. This global aspect of science education is what we strive to emphasize and promote through our institution.
        </p>
      </div>


    <!-- Part: 8 -->
    <el-row justify="center">
      <div class="sect-title">Contact US</div>
      <div class="sect-contact">
        <p>
          Office - USA
        </p>
        <p>Address: P.O. Box 68, Ashton, Maryland 20861</p>
        <p>Phone: 301-875-8556</p>
        <p>E-mail: info@ysassociation.org</p>
        <p>Website: www.ysassociation.org</p>
      </div>
    </el-row>
    </div>
  
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
  import Header from '../components/Header.vue'
  import Footer from "../components/Footer.vue";
 
  export default {
    name: "Home",
    data() {
      return {

      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
    },

    methods: {
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 1024px;
  margin: 10px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.5 ;
  .sect {      
    // font-size: px;
    font-weight: 700;
    color: #fff;
    background-color: #8dc641;
    // letter-spacing: 0px;
  }
  .sect-desc{
    text-align: left;
    line-height: 1.25 ;
    padding: 5px 2px;
    font-size: 14px;
    color:#333;
    // p::before{
    //   content: '•';
    //   margin-right: 5px;
    // }
  }
  > p{
    font-size: 14px;
    font-weight: 200;
  }
  > li{
    font-size: 12px;
    color: #123d80;
  }
  > li::marker{
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
}

.txt-link{
  font-size: 14px;
}
.txt-red{
  color: red;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}



//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 36px !important;
    }
  }
}
</style>
